import { ReactElement } from "react";

import { imageParser } from "../../../shared/utils/image.utils";
import { useViewport } from "../../../shared/hooks";
import { DownloadAppButtons } from "../../common/DownloadApp/DownloadAppButtons";
import { AdvantageWrapper } from "./Advantage.styles";

type BulletPoint = {
  bulletPoint: string;
  count: string;
  benefit: string;
};

export const AppShowcaseSlide = ({
  advantageDescription,
  title,
  titleImage,
  advantageImage,
  benefits,
  isShoho,
  isTc,
  isKenkoPay,
}: {
  title: string;
  titleImage: string;
  benefits: BulletPoint[] | undefined;
  advantageDescription: string;
  advantageImage: string;
  isShoho: boolean;
  isTc: boolean;
  isKenkoPay: boolean;
}): ReactElement => {
  const below600 = useViewport(600).below;

  const getImage = () => {
    if (isShoho) {
      if (below600) return "shoho_store_mobile.svg";
      else {
        return advantageImage;
      }
    }
    if (isTc) {
      if (below600) return "trueCashless_mobile.svg";
      else {
        return advantageImage;
      }
    }
    if (isKenkoPay) {
      if (below600) return "kenkopay_mobile.svg";
      else {
        console.log("else****");
        return advantageImage;
      }
    }
  };

  const getTitleImageCoOrdinates = () => {
    if (isShoho && below600) {
      return {
        width: "129px",
        height: "47px",
      };
    }
    if (isKenkoPay && below600) {
      return {
        width: "151px",
        height: "24px",
      };
    }
    if (isTc && below600) {
      return {
        width: "147px",
        height: "31px",
      };
    }
  };

  return (
    <AdvantageWrapper>
      <div
        style={{
          padding: isTc && !below600 ? "0 0 0 0" : "",
        }}
        className="advantage-wrapper"
      >
        <div className="advantage-info-wrapper">
          {isShoho && (
            <img
              style={{ ...getTitleImageCoOrdinates() }}
              className="advantage-info-image"
              src={imageParser(`kenko_advantage_section/${titleImage}`)}
              alt="kenko pay"
            />
          )}
          <p
            style={{ marginTop: `${isShoho ? "1rem" : "0"}` }}
            className="advantage-info-title"
          >
            {title}
          </p>
          {!isShoho && (
            <img
              style={{ ...getTitleImageCoOrdinates() }}
              className="advantage-info-image"
              src={imageParser(`kenko_advantage_section/${titleImage}`)}
              alt="kenko pay"
            />
          )}
          {benefits && (
            <ul className="advantage-info-benefits">
              {benefits.map(({ benefit, bulletPoint, count }, index) => (
                <>
                  <li className="benefit">
                    {bulletPoint}
                    <span className="benefit benefit-count">{count} </span>
                    {benefit}
                  </li>
                  {below600 && index !== 2 && (
                    <div className="benefit-divider"></div>
                  )}
                </>
              ))}
            </ul>
          )}
          <p
            style={{
              width: isShoho ? "90%" : isKenkoPay && !below600 ? "60%" : "",
            }}
            className="advantage-info-description"
          >
            {advantageDescription}
          </p>
          {isShoho && (
            <>
              <p className="advantage-shoho-description">
                From thermometers to toothpaste, diapers to dental care -
                everything at your fingertips!
              </p>
              <i className="advantage-shoho-description">
                Now say hello to savings ALL DAY, EVERYDAY!
              </i>
            </>
          )}
          <div className="advantage-action-wrapper">
            <p className="advantage-action-title">Download app:</p>
            <DownloadAppButtons />
          </div>
        </div>

        <img
          className={!below600 ? "advantage-image-for-desktop" : ""}
          src={imageParser(`kenko_advantage_section/${getImage()}`)}
          alt="Shoho advantage"
        />
      </div>
    </AdvantageWrapper>
  );
};

// type AppShowcaseSlideProps = {
//   /**
//    * What background color to use
//    */
//   backgroundColor: string;
//   /**
//    * Parent contain display Attritube
//    */
//   display: string;
//   onClick: Function;

//   data: SectionContent;
// } & typeof defaultProps;

const defaultProps = {
  id: 0,
  backgroundColor: "",
  display: "block",
  onClick: () => {},
  data: {},
};

// interface SectionContent {
//   id: number;
//   HERO_IMAGE: string;
//   HERO_IMAGE_ALT: string;
//   TITLE: string;
//   SUB_TITLE: string;
//   TITLE_M: string;
//   SUB_TITLE_M: string;
// }

AppShowcaseSlide.defaultProps = defaultProps;
