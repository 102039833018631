import styled from "@emotion/styled";

export const AdvantageWrapper = styled.div`
  font-family: Poppins;
  background: #102f31;
  padding: 48px 0 0 58px;
  border-radius: 20px;
  height: 100%;

  @media (max-width: 600px) {
    height: fit-content;
    margin: 1rem;
  }

  .slick-dots {
    margin-top: 5rem;
  }

  .advantage-image-for-desktop {
    border-bottom-right-radius: 20px;
    height: 465px;
    width: 509px;
  }

  .advantage-wrapper {
    /* border: 1px solid red; */
    /* height: 100%; */
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
  }

  .advantage-info-title {
    color: #fff;
    width: 80%;
    font-size: 32px;
    font-weight: 700;
    line-height: 140%;
  }

  .advantage-info-benefits {
    list-style-type: none;
    margin-top: 26px;
    padding: 0;
  }

  .benefit {
    color: #bed0a2;
    font-size: 20px;
    font-weight: 400;
    line-height: 180%; /* 36px */
  }

  .benefit-count {
    font-weight: 700;
    margin-left: 5px;
  }

  .advantage-info-wrapper {
    margin-bottom: 2rem;
    /* padding-bottom: 48px; */
  }

  .advantage-info-wrapper {
    /* border: 1px solid red; */
  }

  /* .advantage-info-image {
    margin-top: 26px;
  } */

  .advantage-action-title {
    color: #fafafa;
    font-size: 14px;
    font-weight: 700;
    line-height: 150%; /* 21px */
  }

  .advantage-shoho-description {
    color: #f7cc5f;
    font-family: Figtree;
    font-size: 18px;
    font-weight: 400;
    width: 85%;
  }

  .advantage-info-description {
    color: #f6f6f6;
    font-size: 16px;
    font-weight: 400;
    width: 85%;
  }

  @media (max-width: 600px) {
    padding: 27px 39px 0 26px;
    /* height: 720px; */

    .advantage-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      height: 100%;
    }

    .advantage-info-wrapper {
      width: 100%;
      padding-top: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
    }

    .advantage-info-title {
      text-align: center;
      font-size: 20px;
      width: 100%;
      margin: 0;
    }

    .advantage-info-description {
      font-size: 12px;
      text-align: center;
      width: 100%;
      margin-top: 0;
    }
    .advantage-info-benefits {
      margin-top: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: 80%;
    }

    .advantage-shoho-description {
      font-size: 12px;
      width: 100%;
      text-align: center;
    }

    .benefit {
      font-size: 12px;
    }

    .benefit-divider {
      width: 2px;
      height: 1rem;
      background-color: #bed0a2;
      margin: 0 5px;
    }

    .advantage-image-for-mobile {
      /* margin-top: auto; */
    }

    .advantage-action-title {
      text-align: center;
    }
  }
`;
