import React, { ReactElement, useEffect } from "react";
import appShowcaseContent from "../../../content/AppShowcaseContent";
import { Grid } from "@mui/material";

import styled from "@emotion/styled";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import SwiperCore, { Autoplay, Pagination } from "swiper";

// import CarouselBar from "../../../shared/icons/CarouselBar";
import { AppShowcaseSlide } from "./AppShowcaseSlide";
import { imageParser } from "../../../shared/utils/image.utils";
import { useHasMounted, useViewport } from "../../../shared/hooks";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useInView } from "react-intersection-observer";
import { useDispatch } from "react-redux";
import { backendDrivenEvents } from "../../../slices/onboarding.slice";
import { backendEvents } from "../../../shared/constants/backend-events";
import { Swiper, SwiperSlide } from "swiper/react";

const StyleWrapper = styled.div`
  max-width: 1536px;
  margin: 0 auto;
  position: relative;
  padding: 0 6rem;

  @media (max-width: 600px) {
    padding: 0;
  }

  .mobileView {
    padding: 66px 5vw;
  }
  .root {
    position: relative;
  }
  .stickyElementContainer {
    position: sticky;
    top: 0px;
    height: 75px;
    width: 100%;
  }
  .stickyElement {
    position: absolute;
    top: 274px;
    right: 168px;
    width: 356px;
    height: 574px;
    transform: scale(0.9);
  }

  .sectioncontainer {
    height: fit-content;
    scroll-snap-points-y: repeat(320px);
    scroll-snap-type: y mandatory;
    background-color: #eef4ef;
  }

  .slick-dots {
    bottom: -5rem;
  }

  .swiper-pagination-bullet {
    height: 14px;
    border-radius: 50%;
    width: 14px;
    background-color: #d9d9d9;
  }

  .swiper-pagination-bullet-active {
    height: 14px;
    width: 14px;
    color: #003034;
    background-color: #003034;
    border-radius: 50%;
  }

  .swiper {
    position: static;
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    right: 0;
    top: 44rem;
    z-index: 1000;
  }

  @media (max-width: 600px) {
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      top: 47rem;
    }
  }
`;

SwiperCore.use([Autoplay, Pagination]);

export const ParallaxWrapper = (): ReactElement => {
  const mounted = useHasMounted();
  // const SliderWrapper = styled(Slider)(sliderCls);
  // const appShowcaseSliderSettings = {
  //   ...props.sliderSettings,
  //   nextArrow: <></>,
  //   prevArrow: <></>,
  //   autoplay: false,
  //   autoplaySpeed: 3000,
  //   speed: 1000,
  //   // infinite: true,
  //   dots: true,
  //   centerMode: true,
  //   initialSlide: 1,
  //   slidesToShow: 1,
  //   customPaging: function () {
  //     return (
  //       <a>
  //         <CarouselBar background={"#71A4A5"} />
  //       </a>
  //     );
  //   },
  // };

  const { ref, inView } = useInView();
  const below600 = useViewport(600).below;
  const dispatch = useDispatch();

  useEffect(() => {
    if (inView) {
      dispatch(
        backendDrivenEvents({
          ...backendEvents.HOMEPAGE_KENKO_ADVANTAGE_SECTION,
        })
      );
    }
  }, [inView, dispatch]);

  const arr = [
    {
      title: "A health plan you can use everyday of the year.",
      titleImage: "shoho.svg",
      isKenkoPay: false,
      isTc: false,
      isShoho: true,
      advantageDescription: `Introducing the SHOHO store - your very own marketplace where you can choose from over 3 Lakh wellness and daily 
      healthcare products at discounted prices!`,
      advantageImage: "Shoho_Store_Advantage.png",
    },
    {
      title: "Cashless is the thing of past. Now experience",
      titleImage: "trucashless.svg",
      isShoho: false,
      isKenkoPay: false,
      isTc: true,
      benefits: [
        { bulletPoint: "🏥", count: "400+", benefit: "Hospitals" },
        { bulletPoint: "⏰", count: "", benefit: "Priority Treatment" },
        { bulletPoint: "✅", count: "", benefit: "Trucashless Payments" },
      ],
      advantageDescription: `Get the best hospital treatment without paying a single penny out of your pockets.`,
      advantageImage: "Truecashess_Advantage.png",
    },
    {
      title: "Revolutionising OPD payments with",
      titleImage: "kenko_pay.svg",
      isShoho: false,
      isTc: false,
      isKenkoPay: true,
      benefits: [
        { bulletPoint: "🏥", count: "15000+", benefit: "Hospitals" },
        { bulletPoint: "📍", count: "100+", benefit: "Cities" },
        { bulletPoint: "🥼", count: "15+", benefit: "Specialities" },
      ],
      advantageDescription: `We bring to you a hassle-free experience - 
    an instant payment solution that takes away the waiting time for reimbursements. All
    you need to do is scan the QR code at the doctor and we will foot
    the bill.`,
      advantageImage: "Kenko_Pay.png",
    },
  ];

  const AdvantageTitle = styled.p`
    color: #243242;
    text-align: center;
    font-family: Figtree;
    font-size: 42px;
    font-weight: 600;
    margin: 0;
    padding: 35px 0;

    @media (max-width: 600px) {
      font-size: 20px;
    }
  `;

  const AdvantageFooterText = styled.p`
    color: #3e4b5b;
    text-align: center;
    font-family: Figtree;
    font-size: 24px;
    font-weight: 500;
    margin-top: 5rem;

    @media (max-width: 600px) {
      font-size: 14px;
      margin: 4rem auto 0 auto;
      width: 60%;
    }
  `;

  const swiperParams = {
    pagination: {
      clickable: true, // allows clicking on the pagination dots to navigate to the corresponding slide
    },
    centeredSlides: true,
    autoplay: {
      delay: 3000, // set the autoplay delay in milliseconds
      disableOnInteraction: false, // enable autoplay even when the user interacts with slides
    },
  };

  return (
    <>
      {mounted && (
        <StyleWrapper ref={ref}>
          <Grid container>
            <Grid
              style={{
                paddingBottom: below600 ? "3rem" : "0",
              }}
              item
              xs={12}
            >
              <AdvantageTitle>The exclusive Kenko advantage</AdvantageTitle>
              <Swiper {...swiperParams}>
                {arr.map(
                  ({
                    advantageDescription,
                    advantageImage,
                    benefits,
                    title,
                    titleImage,
                    isShoho,
                    isTc,
                    isKenkoPay,
                  }) => (
                    <SwiperSlide key={title}>
                      <AppShowcaseSlide
                        advantageDescription={advantageDescription}
                        advantageImage={advantageImage}
                        benefits={benefits}
                        isShoho={isShoho}
                        isTc={isTc}
                        title={title}
                        titleImage={titleImage}
                        isKenkoPay={isKenkoPay}
                      />
                    </SwiperSlide>
                  )
                )}
              </Swiper>
              <AdvantageFooterText>
                This is the future of health insurance and you deserve every
                part of it!
              </AdvantageFooterText>
            </Grid>
          </Grid>
        </StyleWrapper>
      )}
    </>
  );
};

export default ParallaxWrapper;

// type ParallaxWrapperProps = {
//   primary?: boolean;
//   /**
//    * What background color to use
//    */
//   backgroundColor?: string;
//   /**
//    * Parent contain display Attritube
//    */
//   display?: string;
//   /**
//    * Section label
//    */
//   label?: string;
//   /**
//    * Optional click handler
//    */
//   onClick: Function;
//   /**
//    * Optional animation flag
//    */
//   disableAnimation?: boolean;
//   centerMode?: boolean;
//   data?: AppShowcaseContent;
//   stickyElement?: React.ReactNode;
//   height?: string;
//   sliderSettings: Settings;
// } & typeof defaultProps;

const defaultProps = {
  backgroundColor: "",
  primary: false,
  display: "block",
  onClick: () => {},
  disableAnimation: false,
  label: "section",
  centerMode: false,
  data: {},
  stickyElement: (
    <img
      loading="lazy"
      src={imageParser(appShowcaseContent.MOBILE_WRAPPER_IMAGE)}
      alt=""
    />
  ),
  height: "674px",
  sliderSettings: {},
};

// interface SectionContent {
//   id: number;
//   HERO_IMAGE: string;
//   TITLE: string;
//   SUB_TITLE: string;
//   TITLE_M: string;
//   SUB_TITLE_M: string;
// }

// interface AppShowcaseContent {
//   MOBILE_WRAPPER_IMAGE: string;
//   CONTENT_DOWNLOAD_APP_SECTION: SectionContent[];
// }

ParallaxWrapper.defaultProps = defaultProps;
